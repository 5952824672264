
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "transport-modal",
  computed: {
    hasErrors: function (field) {
      if (field == "hola") {
        return true;
      }
      return false;
    },
  },
  methods: {
    getButtonText: function () {
      return "Continuar";
    },
    previousStep() {
      this.currentStepIndex--;
    },
    handleStep(values) {
      this.currentStepIndex++;
    },
    formSubmit() {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        window.location.reload();
      });
    },
  },
  mounted() {
    this.errors["name"] = "Gil";
  },
  data() {
    return {
      stepperObj: null,
      currentStepIndex: 0,
      totalSteps: 1,
      errors: {},
      data: {
        name: "Empresa S.A.",
        cuil: "No puede ser",
      },
      form: {
        endpoint: "transport",
        entityName: "Transporte",
        steps: [
          {
            name: "Datos Basicos",
            fields: [
              {
                name: "name",
                label: "Razón Social",
                type: "text",
              },
              {
                name: "cuil",
                label: "CUIL",
                type: "text",
              },
            ],
          },
        ],
      },
    };
  },
});
